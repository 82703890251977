import { IStation } from "Api/V1/Models";

export interface IButtonProps {
	onClick: React.MouseEventHandler<HTMLElement>;
	disabled?: boolean;
	text?: string;
}

function ButtonContent(props: { iconName: string; text?: string | null }) {
	return (
		<>
			<i className={"bi " + props.iconName + (props.text != null ? " me-2" : "")}></i>
			{props.text}
		</>
	);
}

export function StarButton(props: { station: IStation; text?: boolean }) {
	const station = props.station;

	return (
		<a href={station.links.application + "#star"} className="btn btn-warning" data-track-click="Star" data-label={station.title} title={`Star ${station.title}`} rel="nofollow">
			<ButtonContent iconName="bi-star-fill" text={props.text === true ? "Star" : null} />
		</a>
	);
}

export function PlayButton(props: { station: IStation; text?: boolean }) {
	const station = props.station;

	return (
		<a href={station.links.application + "#connect"} className="btn btn-warning" data-track-click="Play" data-label={station.title} title={`Play ${station.title}`} rel="nofollow">
			<ButtonContent iconName="bi-play-fill" text={props.text === true ? "Play" : null} />
		</a>
	);
}

export function BackButton(props: { href: string; text?: string }) {
	return (
		<a href={props.href} className="btn btn-outline-secondary">
			<ButtonContent iconName="bi-chevron-left" text={props.text} />
		</a>
	);
}

export function DeleteButton(props: IButtonProps) {
	return (
		<button className={"btn btn-outline-danger" + (props.disabled === true ? " disabled" : "")} onClick={props.onClick}>
			<ButtonContent iconName="bi-trash-fill" text={props.text} />
		</button>
	);
}

export function RestoreButton(props: IButtonProps) {
	return (
		<button className={"btn btn-outline-success" + (props.disabled === true ? " disabled" : "")} onClick={props.onClick}>
			<ButtonContent iconName="bi-arrow-counterclockwise" text={props.text} />
		</button>
	);
}

export function SaveButton(props: IButtonProps) {
	return (
		<button className={"btn btn-success" + (props.disabled === true ? " disabled" : "")} onClick={props.onClick}>
			<ButtonContent iconName="bi-save" text={props.text} />
		</button>
	);
}
